import React, { useCallback, useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { RegisterOptions, useFormContext } from 'react-hook-form'
import { useLazyQuery, useQuery } from '@apollo/client'
import { Button, OrderPriceList, RadioButton, UserAddress, ValidationErrorMessage } from '@elements'
import { filters, golfClubModel as graphqlGolfClubModel, golfClubType as graphqlGolfClubType, user } from '@graphql'
import {
  useGolfClubModelFlexes,
  useGraphqlGolfClub,
  useGraphqlGolfClubDraft,
  useLocationWithState,
  useWindowSize
} from '@hooks'
import { AccountLayout } from '@layouts'
import { Form as FormModule } from '@modules'
import { AccountPage } from '@pages/components'
import {
  AddressCard,
  CacheUser,
  Category,
  CreateGolfClubArgs,
  CreateGolfClubDraftArgs,
  Filters,
  GolfClubDraft,
  GolfClubModel,
  GolfClubType,
  PlaceAClubFormType,
  ResponseType,
  SearchGolfClubTypeArgs
} from '@types'
import { addDays, bringingToInputNumber, getGolfClubInfoByCategory, noOnlyWhitespaceValidation } from '@utils'
import { navigate } from 'gatsby'
import './place-a-club.scss'

interface DesctopPlaceClubFormRadioGroupProps {
  name: string
  title: string
  validation?: RegisterOptions
  labels: string[]
}

const DesctopPlaceClubFormRadioGroup: React.FC<DesctopPlaceClubFormRadioGroupProps> = props => {
  const { name, title, validation, labels } = props
  const { register, watch, setValue } = useFormContext()

  const formValue = watch(name)

  const formOptions = labels.map(label => ({ label, value: label }))

  useEffect(() => {
    register(name, validation)
  }, [])

  return (
    <div className={'desctop-radio-group'}>
      <label htmlFor={name} data-required={!!validation?.required}>
        {title}
      </label>
      <div className={'desctop-radio-group-options'}>
        {formOptions.map((field, index) => {
          const checked = field.value === formValue
          return (
            <div key={index} className={'desctop-radio-group-options-item'}>
              <RadioButton
                key={field.value}
                name={`${name}.${index}`}
                checked={checked}
                onChange={() => setValue(name, field.value)}
                label={field.label}
              />
            </div>
          )
        })}
        <ValidationErrorMessage name={name} />
      </div>
    </div>
  )
}

const Form = FormModule<PlaceAClubFormType>()

interface PlaceAClubFormTypeProps {}

const EXPIRATION_DAYS = [
  { label: '10 Days', value: addDays(new Date(), 10).toString() },
  { label: '20 Days', value: addDays(new Date(), 20).toString() },
  { label: '30 Days', value: addDays(new Date(), 30).toString() }
]

const PlaceAClubForm: React.FC<PlaceAClubFormTypeProps> = props => {
  const alert = useAlert()

  const { toRender: isMobileScreen } = useWindowSize(['mobile', 'landscape'])
  const { state: locationState } = useLocationWithState<{ golfClubDraft: GolfClubDraft }>()

  const { data: currentUserData } = useQuery<ResponseType<CacheUser>>(user.FetchCurrentUser, {
    fetchPolicy: 'cache-only'
  })
  const { data: filterData } = useQuery<{ res: Filters }>(filters.FetchFilters, { fetchPolicy: 'cache-only' })

  const [fetchGolfClubModel, { data: golfClubModelData }] = useLazyQuery<{
    res: Pick<GolfClubModel, 'retailPrice' | 'id' | 'category' | 'brand' | 'golfClubTypes'>
  }>(graphqlGolfClubModel.FetchGolfClubModelRetailPrice)

  const [fetchGolfClubModelOptions, { data: golfClubModelOptions }] = useLazyQuery(
    graphqlGolfClubType.FetchGolfClubModelOptions
  )

  const { golfClubTypeFlex, fetchGolfClubFlex } = useGolfClubModelFlexes()

  const [searchGolfClubType, { data: golfClubTypeData, loading: golfClubTypeLoading }] = useLazyQuery<{
    res: GolfClubType[]
  }>(graphqlGolfClubType.SearchGolfClubType)

  const { createGolfClubDraft, updateGolfClubDraft } = useGraphqlGolfClubDraft()
  const { createGolfClub } = useGraphqlGolfClub()

  const [selectedCategory, setSelectedCategory] = useState<Category | undefined>()
  const [createGolfClubArgs, setCreateGolfClubArgs] = useState<Omit<CreateGolfClubArgs, 'golfClubTypeId'>>(
    {} as CreateGolfClubArgs
  )

  const currentUser = currentUserData?.res
  const currentUserAddressCard = currentUser?.defaultAddressCard
  const currentUserPaymentCard = currentUser?.defaultPaymentCard

  const categories = filterData?.res?.categories
  const selectedGolfClubModel = golfClubModelData?.res

  const formattedFlexOptions = golfClubTypeFlex.map(flex => flex.toUpperCase()) || []

  const getExpirationDays = () => {
    const MILLISECONDS_TO_DAYS_DIVIDER = 1000 * 60 * 60 * 24

    if (locationState?.golfClubDraft?.expiresAt && locationState?.golfClubDraft?.updatedAt) {
      const {
        golfClubDraft: { expiresAt, updatedAt }
      } = locationState

      const expirationDate = new Date(expiresAt)
      const updatedAtDate = new Date(updatedAt)
      const dateDifference = +expirationDate - +updatedAtDate
      const dateDifferenceInDays = Math.round(dateDifference / MILLISECONDS_TO_DAYS_DIVIDER)

      switch (dateDifferenceInDays) {
        case 10:
          return EXPIRATION_DAYS[0]
        case 20:
          return EXPIRATION_DAYS[1]
        case 30:
          return EXPIRATION_DAYS[2]
        default:
          return EXPIRATION_DAYS[0]
      }
    }
  }

  const handleSaveAsDraft = async (form: PlaceAClubFormType) => {
    if (!selectedGolfClubModel) return
    const { dexterity, flex, option, condition, price, expiresAt: expiration, golfClubModelId, categoryId } = form
    const expiresAt = new Date(expiration).toISOString().split('T')[0]
    const brandId = selectedGolfClubModel?.brand?.id
    const golfClubDraftId = locationState?.golfClubDraft?.id

    const golfClubDraftArgs: CreateGolfClubDraftArgs = {
      dexterity,
      flex,
      condition,
      option,
      price,
      categoryId,
      golfClubModelId,
      brandId,
      expiresAt
    }

    if (golfClubDraftId) {
      await updateGolfClubDraft(golfClubDraftArgs, golfClubDraftId)

      return
    } else {
      await createGolfClubDraft(golfClubDraftArgs)

      return
    }
  }

  const handleCreateGolfClub = async (variables: PlaceAClubFormType) => {
    if (!currentUserPaymentCard) {
      alert.show('Error! Payment card is missing.', { type: 'error' })
      return null
    }

    if (!currentUserAddressCard) {
      alert.show('Error! Address card is missing.', { type: 'error' })
      return null
    }

    if (!selectedGolfClubModel) {
      alert.show('Error! Please, select golfclub model.', { type: 'error' })
      return null
    }

    if (selectedGolfClubModel && currentUserAddressCard && currentUserPaymentCard) {
      const { dexterity, flex, option, condition, price, expiresAt: expiration } = variables

      const golfClubModelId = selectedGolfClubModel.id
      const addressCardId = currentUserAddressCard.id
      const paymentCardId = currentUserPaymentCard.id

      const expiresAt = new Date(expiration).toISOString().split('T')[0]

      const golfClubType: SearchGolfClubTypeArgs = {
        dexterity,
        flex,
        condition,
        option,
        golfClubModelId
      }
      setCreateGolfClubArgs({
        price,
        expiresAt,
        addressCardId,
        paymentCardId
      })
      searchGolfClubType({ variables: { golfClubType } })
    }
  }

  const handleCreateGolfClubEffect = useCallback(() => {
    const golfClubType = golfClubTypeData?.res[0]

    const { price, expiresAt } = createGolfClubArgs

    if (golfClubType && currentUserAddressCard && currentUserPaymentCard) {
      !golfClubType && alert.show('Golf club type does not exist', { type: 'error' })
      const addressCardId = currentUserAddressCard.id
      const paymentCardId = currentUserPaymentCard.id
      const golfClubTypeId = golfClubType.id

      createGolfClub({
        expiresAt,
        price,
        golfClubTypeId,
        addressCardId,
        paymentCardId
      }).then(() => navigate('/account/store/my-golf-club-inventory'))
    }
  }, [golfClubTypeData])

  useEffect(handleCreateGolfClubEffect, [golfClubTypeData, golfClubTypeLoading])

  const defaultExpiration = getExpirationDays()

  if (isMobileScreen) return <AccountPage.MobilePlaceClubForm />

  const renderUserAddressCards = (addressCard?: AddressCard) => {
    return (
      <div className={'place-a-club-addresses'}>
        <div className={'place-a-club-addresses-hint'}>
          <b>Note: </b>The address on your profile will be used as the return address on the shipping label.
        </div>
        {!addressCard ? (
          <div>
            <div>Address</div>
            <hr />
            <Button className={'place-a-club-addresses-button'} to={'/account/profile'} type={'roundWithBg'}>
              Go to My Profile
            </Button>
          </div>
        ) : (
          <div className={'place-a-club-addresses-list'}>
            <UserAddress addressCard={addressCard} />
          </div>
        )}
      </div>
    )
  }

  return (
    <AccountLayout pageClassName={'place-a-club'}>
      <p className={'text-20 account-layout-page-title'}>Create Club Listing</p>
      {renderUserAddressCards(currentUserAddressCard)}
      {!!currentUserAddressCard && (
        <Form
          onSubmit={handleCreateGolfClub}
          onError={() => null}
          defaultValues={{
            ...(locationState?.golfClubDraft || {}),
            categoryId: locationState?.golfClubDraft?.category?.id,
            golfClubModelId: locationState?.golfClubDraft?.golfClubModel?.id,
            expiresAt: defaultExpiration?.value
          }}
        >
          {({ watch, getValues, setValue }) => {
            const golfClubItemsOptions = golfClubModelOptions?.res?.map((item: string, index: number) => ({
              label: item.toString(),
              value: item.toString()
            }))

            return (
              <>
                <div className={'account-place-a-club-section'}>
                  <Form.CategorySelect
                    label={'CATEGORY'}
                    placeholder={'Select a category'}
                    input={{ direction: 'horizontal', labelClassName: 'default-subtitle' }}
                    name={'categoryId'}
                    onChange={categoryId => {
                      const category = categories?.find(category => categoryId === category.id)
                      setSelectedCategory(category)
                      setValue('golfClubModelId', '')
                    }}
                    validation={{ required: { value: true, message: 'Category is required' } }}
                  />
                  <Form.GolfClubModelAutocomplete
                    label={'CLUB MODEL'}
                    input={{ direction: 'horizontal', labelClassName: 'default-subtitle' }}
                    filters={selectedCategory && { categoryIds: [selectedCategory?.id] }}
                    disabled={!selectedCategory}
                    name={'golfClubModelId'}
                    onChange={golfClubModelId => {
                      golfClubModelId && fetchGolfClubModel({ variables: { id: golfClubModelId } })
                      golfClubModelId && fetchGolfClubModelOptions({ variables: { golfClubModelId: golfClubModelId } })
                      golfClubModelId && fetchGolfClubFlex({ variables: { golfClubModelId: golfClubModelId } })
                    }}
                    validation={{
                      required: { value: true, message: 'Golf club model is required' }
                    }}
                  />
                  <DesctopPlaceClubFormRadioGroup
                    name={'dexterity'}
                    title={'DEXTERITY'}
                    labels={['LEFT', 'RIGHT']}
                    validation={{
                      required: { value: true, message: 'Dexterity is required' }
                    }}
                  />
                  {formattedFlexOptions.length > 0 && (
                    <DesctopPlaceClubFormRadioGroup
                      name={'flex'}
                      title={'FLEX'}
                      labels={formattedFlexOptions}
                      validation={{
                        required: { value: true, message: 'Flex is required' }
                      }}
                    />
                  )}

                  <DesctopPlaceClubFormRadioGroup
                    name={'condition'}
                    title={'CONDITION'}
                    labels={['NEW', 'GOOD', 'EXCELLENT']}
                    validation={{
                      required: { value: true, message: 'Condition is required' }
                    }}
                  />
                  {!!golfClubItemsOptions && (
                    <Form.Select
                      name={'option'}
                      disabled={!getValues('golfClubModelId')}
                      placeholder={'Specify loft'}
                      label={getGolfClubInfoByCategory(selectedCategory).title}
                      input={{ direction: 'horizontal', labelClassName: 'default-subtitle' }}
                      options={golfClubItemsOptions}
                      validation={{
                        required: {
                          value: true,
                          message: getGolfClubInfoByCategory(selectedCategory).title + ' is required'
                        }
                      }}
                    />
                  )}
                  <div className={'account-place-a-club-section_hint'}>*You must meet the minimum offer of $15</div>
                  <Form.Input
                    name={'price'}
                    autoComplete={'off'}
                    label={'PRICE'}
                    theme={'green'}
                    labelClassName={'default-subtitle'}
                    direction={'horizontal'}
                    placeholder="$0.00"
                    validation={{
                      valueAsNumber: true,
                      required: { value: true, message: 'Price is required' },
                      min: { value: 15, message: 'Minimum offer is $15' },
                      max: { value: 999999, message: 'Price cannot exceed $999999' },
                      ...noOnlyWhitespaceValidation
                    }}
                    onKeyDown={bringingToInputNumber}
                  />
                  <Form.Select
                    className={'account-place-a-club-form-expiration'}
                    label={'EXPIRATION'}
                    input={{
                      direction: 'horizontal',
                      labelClassName: 'default-subtitle',
                      placeholder: 'Select the Expiration'
                    }}
                    name={'expiresAt'}
                    options={EXPIRATION_DAYS}
                    clearable={false}
                    validation={{
                      required: { value: true, message: 'Select the Expiration' }
                    }}
                  />
                </div>
                <div className={'account-place-a-club-footer'}>
                  <div>
                    <OrderPriceList addressCardId={currentUserAddressCard.id} price={watch('price')} />
                  </div>
                  <div className={'account-place-a-club-buttons'}>
                    <Button type={'round'} to={'/account/store/my-golf-club-inventory'}>
                      Сancel
                    </Button>
                    <Button type={'roundBlack'} onClick={() => handleSaveAsDraft(getValues())}>
                      Save as draft
                    </Button>
                    <Form.Submit centered={false} type={'roundWithBg'}>
                      List club for sale
                    </Form.Submit>
                  </div>
                </div>
              </>
            )
          }}
        </Form>
      )}
    </AccountLayout>
  )
}

export default PlaceAClubForm
